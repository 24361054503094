//event listener for bridge ready
/* global mcd */

export const getUserInfo = () => {
  if (process.env.NODE_ENV === 'development') {
    return new Promise((resolve) => {
      resolve({
        email: 'test@testist.com',
        deviceId: '000000',
        firstname: 'Test',
        lastname: 'Test',
      })
    })
  } else {
    let user = mcd.bridge.message('user'); //access to user bridge;
    user.send({"getuser" : true, "promptlogin": true}); //send command "getuser";
    return new Promise((resolve, reject) => {
  
      user.on('data', function(data) {
        resolve(data);
      });
      user.on('error', function(error) {
        reject("getuser error: " + JSON.stringify(error));
      });
      user.on('done', function(){
        console.log('User info is received')
      });
    });
  }
};

export const offerActivation = (loyaltyId, rewardId, tags) => {
  return new Promise((resolve, reject) => {
    let autoActivate = false;
    let offerActivation = mcd.bridge.message('offerActivation');
    offerActivation.send({
      'loyaltyId' : loyaltyId,
      'autoActivate' : autoActivate, //autoActivate true works only if you have only one reward in loyalty card.
      'rewardId' : rewardId
    });

    offerActivation.on('data', (data) => {
      if (tags) {
        tagsToPlexure(tags)
        .then((tagsData) => {
          resolve('Tags sent successfuly, offerActivation data' + JSON.stringify(data));
        })
        .catch(tagsErr => {
          reject('Tags to plexure error: ' + JSON.stringify(tagsErr))
        });
      } else {
        resolve('offerActivation data' + JSON.stringify(data));
      }
    });

    offerActivation.on('error', (error) => {
      reject('offerActivation error' + JSON.stringify(error))
    });
    /*offerActivation.on('done', () => {
      console.log('offerActivation done');
    });*/
  });
}

export const tagsToPlexure = (tags) => {
  let user = mcd.bridge.message('user');

  return new Promise((resolve, reject) => {
    user.send({"addTags": tags});
    user.on('data', function(data) {
      resolve(data);
    });
    user.on('error', function(error) {
      reject(error);
    });
    user.on('done', function() {
      // no more event will be emitted
      console.log("add/remove tags done");
    });
  });
}

export const checkUserData = async () => {
  try {
    const userInfo = await getUserInfo();
    return userInfo;
  } catch (error) {
    console.info("no user info", error);
    throw error;
  }
};
