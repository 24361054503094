import React from 'react';
import Slider from "react-slick";
import { CSSTransition } from 'react-transition-group';

import lottieService from "../../services/lottie.service.js";

import './_Landing.scss';
import "./_Engagement.scss";

import Logo from '../../assets/Logo-engagement.png';
import Loader from '../../assets/loader.svg';

import "../../../node_modules/slick-carousel/slick/slick.css";

import { ENGAGEMENT_DATA } from '../../utils/coupons-data';
import { offerActivation } from '../../utils/_mcd-connector';

class Engagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentItem: 1,
      loading: false,
      activationError: false,
      dataIsLoading: props.userData ? false : true
    };
    this.animationRef = React.createRef();
  }

  componentDidMount() {
    lottieService({
      container: this.animationRef.current,
      animationData: require("../../lottie/loader.json"),
    });
  }

  disableActivationError() {
    setTimeout(() => {
      this.setState({
        activationError: false
      });
    }, 3000)
  }

  activate = () => {
    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
        },
        () => {
          const loyaltyId = ENGAGEMENT_DATA[this.state.currentItem].loyaltyId;
          const rewardId = ENGAGEMENT_DATA[this.state.currentItem].rewardId;

        const setOfferActivation = (loyaltyId, rewardId, tags) =>
          Array.isArray(rewardId)
            ? Promise.all(rewardId.map(i => offerActivation(loyaltyId, i, tags)))
            : offerActivation(loyaltyId, rewardId, tags);

        setOfferActivation(loyaltyId, rewardId, ['McDNL_MDB_3'])
        .then(() => {
          window.location.replace('gmalite://gmalite-loyalty');
        })
        .catch(() => {
          this.setState({
            loading: false,
            activationError: true
          });
          this.disableActivationError();
        })
      })
    }
  }

  render() {
    const {userData} = this.props;
    const firstname = userData ? userData.firstname : 'Job';

    const settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      initialSlide: 1,
      afterChange: (currentItem) => this.setState({ currentItem }),
    };


    const slides = Object.keys(ENGAGEMENT_DATA).map((key, i) => {
      return (
        <div className='slider-item-engagement' key={i}>
          <img src={ENGAGEMENT_DATA[key].image} alt={ENGAGEMENT_DATA[key].title} />
          <p>{ENGAGEMENT_DATA[key].title}</p>
        </div>
      )
    });

    return (
      <section className="landing engagement">
        {this.state.dataIsLoading ? 
          <div className='loader-container'><div ref={this.animationRef} /></div> : 
          <>
            <div className="wrapper">
              <div className="heading">
                <h1>
                  Welkom <br /> {firstname}
                </h1>
                <img src={Logo} alt="McD" />
              </div>
              <p className="desc">
                Bij jouw MyMcDonald’s vol fun, lekkere kortingen en toffe winacties!
                En met MyMcDonald’s Rewards spaar je voor gratis favorieten. Kies ’n
                lekkere welkomst-Deal en start de Good Times!
              </p>
            </div>
            <div className="slider_wrapper">
              <div className="slider_center-card">
              </div>
              <Slider {...settings}>
                {slides}
              </Slider>
            </div>
            <div className="button_wrapper">
              <CSSTransition
                in={this.state.activationError}
                timeout={500}
                unmountOnExit
                classNames="error-container">
                  <p className="error-row">Je hebt al een Welkomst Deal ontvangen, check de Deal in de App.</p>
              </CSSTransition>
              <button className="cta" onClick={this.activate}>
                { this.state.loading ? (<img src={Loader} alt='loading...' />) : (<span>Kies je favoriet</span>) }
              </button>
            </div>
          </>
        }
      </section>
    );
  }
}

export default Engagement;
