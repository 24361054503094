import lottie from "lottie-web";

const lottieService = (data) =>
  lottie.loadAnimation({
    renderer: "svg",
    loop: true,
    autoplay: true,
    ...data,
  });

export default lottieService;
