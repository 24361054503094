import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Engagement from './pages/Landing/Engagement';
import Winback from './pages/Landing/Winback';

import { checkUserData } from './utils/_mcd-connector';
import './App.scss';

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }

  getDataFromBridge = async () => {
    const data = await checkUserData();
    this.setState({userData: data});
  };

  componentDidMount() {
    const _self = this;
    if (process.env.NODE_ENV === "development") {
      _self.getDataFromBridge();
    }

    document.addEventListener("mcdBridgeReady", _self.getDataFromBridge);
  }

  componentWillUnmount() {
    const _self = this;
    document.removeEventListener("mcdBridgeReady", _self.getDataFromBridge);
  }

  render() {
    return (
      <div className="App">          
        <BrowserRouter>
          <div className="router-wrapper">
            <Route
              path="/engagement"
              component={(props) => (
                <Engagement {...props} userData={this.state.userData} />
              )}
            />
            <Route path="/winback" component={Winback} />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
