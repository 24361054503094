import React from 'react';
import Slider from "react-slick";

import './_Landing.scss';

import Logo from '../../assets/logo.png';
import Loader from '../../assets/loader.svg';

import "../../../node_modules/slick-carousel/slick/slick.css";

import { WINBACK_DATA } from '../../utils/coupons-data';
import { offerActivation } from '../../utils/_mcd-connector';

class Winback extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      currentItem: 1,
      loading: false
    }
  }

  componentDidMount () {
  }

  activate = () => {
    if (!this.state.loading) {
      this.setState({
        loading: true
      }, () => {
        const loyaltyId = WINBACK_DATA[this.state.currentItem].loyaltyId;
        const rewardId = WINBACK_DATA[this.state.currentItem].rewardId;

        offerActivation(loyaltyId, rewardId, ['McDNL_MDB_3'])
        .then(() => {
          window.location.replace('gmalite://gmalite-loyalty');
        })
        .catch((err) => {
          this.setState({
            loading: false
          }, () => alert(err));
        })
      })
    }
  }

  render() {
    const settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      initialSlide: 1,
      afterChange: currentItem => this.setState({ currentItem })
    };

    const price = WINBACK_DATA[this.state.currentItem].price.split(',');

    const slides = Object.keys(WINBACK_DATA).map((key, i) => {
      return (
        <div className='slider-item' key={i}>
          <img className={i === 2 ? 'smaller-img' : ''} src={WINBACK_DATA[key].image} alt={WINBACK_DATA[key].title} />
        </div>
      )
    })

    return (
      <section className="landing">
        <div className="wrapper">
          <div className="heading">
            <img src={Logo} alt='McD' />
            <h1>Omdat we je zo missen...</h1>
          </div>
          <p className="desc">
          ...iets speciaals voor jou: een Coupon waar je u tegen zegt! Kies er eentje uit waar je hart sneller van gaat kloppen en geniet.
          </p>
        </div>
        <div className="slider_wrapper">
          <div className="slider_center-card-winback">
            <span className="slider_price label">{price[0]},<sub>{price[1]}</sub></span>
          </div>
          <Slider {...settings}>
            {slides}
          </Slider>
        </div>
        <div className="button_wrapper">
          <button className="cta" onClick={this.activate}>
            { this.state.loading ? (<img src={Loader} alt='loading...' />) : (<span>Kies favoriet</span>) }
          </button>
        </div>
      </section>
    );
  }
}

export default Winback;
