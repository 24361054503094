import McKroket from '../assets/McKroket.png';
import McNuggets from '../assets/McNuggets.png';
import Fries from '../assets/fries.png';
import Friesn from '../assets/card-fries.png';
import Nuggetsm from '../assets/card-nuggets.png';
import Menu from '../assets/card-bm.png';
import McFlurry from '../assets/card-flurry.png';



const ENGAGEMENT_STAGING = [
  {
    title: '1x Big Mac medium menu voor €6,00',
    price: '5,75',
    loyaltyId: 1874,
    rewardId: [72377, 72375],
    image: Menu,
    tag: "McDNL_MDB_3"
  }, {
    title: '1x Nuggets medium menu voor €6,25',
    price: '6,-',
    loyaltyId: 1874,
    rewardId: [72374, 72370],
    image: Nuggetsm,
    tag: "McDNL_MDB_3"
  }, {
    title: '1x McFlurry voor €2,50',
    price: '2,50',
    loyaltyId: 1874,
    rewardId: 72362,
    image: McFlurry,
    tag: "McDNL_MDB_3"
  }, {
    title: '1x medium friet voor €1,75',
    price: '1,90',
    loyaltyId: 1874,
    rewardId: 72367,
    image: Friesn,
    tag: "McDNL_MDB_3"
  }
]

const WINBACK_STAGING = [
  {
    title: '1x Chicken McNuggets 9 voor €3,50',
    price: '3,50',
    loyaltyId: 1617,
    rewardId: 23479,
    image: McNuggets,
    tag: "McDNL_MDB_3"
  }, {
    title: '1x Medium Franse Friet voor €2',
    price: '2,-',
    loyaltyId: 1617,
    rewardId: 23481,
    image: Fries,
    tag: "McDNL_MDB_3"
  }, {
    title: '1x McKroket voor €2,30',
    price: '2,30',
    loyaltyId: 1617,
    rewardId: 23480,
    image: McKroket,
    tag: "McDNL_MDB_3"
  }
]

const ENGAGEMENT_PROD = [
  {
    title: '1x Big Mac medium menu voor €6,00',
    price: '5,75',
    loyaltyId: 1874,
    rewardId: [72377, 72375],
    image: Menu,
    tag: "McDNL_MDB_3"
  }, {
    title: '1x Nuggets medium menu voor €6,25',
    price: '6,-',
    loyaltyId: 1874,
    rewardId: [72374, 72370],
    image: Nuggetsm,
    tag: "McDNL_MDB_3"
  }, {
    title: '1x McFlurry voor €2,50',
    price: '2,50',
    loyaltyId: 1874,
    rewardId: 72362,
    image: McFlurry,
    tag: "McDNL_MDB_3"
  }, {
    title: '1x medium friet voor €1,75',
    price: '1,90',
    loyaltyId: 1874,
    rewardId: 72367,
    image: Friesn,
    tag: "McDNL_MDB_3"
  }
]

const WINBACK_PROD = [
  {
    title: '1x Chicken McNuggets 9 voor €3,50',
    price: '3,50',
    loyaltyId: 518,
    rewardId: 12443,
    image: McNuggets,
    tag: "McDNL_MDB_3"
  }, {
    title: '1x Medium Franse Friet voor €2',
    price: '2,-',
    loyaltyId: 518,
    rewardId: 12445,
    image: Fries,
    tag: "McDNL_MDB_3"
  }, {
    title: '1x McKroket voor €2,30',
    price: '2,30',
    loyaltyId: 518,
    rewardId: 12444,
    image: McKroket,
    tag: "McDNL_MDB_3"
  }
]

export const ENGAGEMENT_DATA = process.env.REACT_APP_ENV === 'production' ? ENGAGEMENT_PROD : ENGAGEMENT_STAGING;

export const WINBACK_DATA = process.env.REACT_APP_ENV === 'production' ? WINBACK_PROD : WINBACK_STAGING;
